import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle,UncontrolledTooltip } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../../assets/scss/datatables.scss";
const axios = require('axios');


const DatatableTables = () => {
    const [list, setlist] = useState([]);
    const [count, setCount] = useState(0);
    const [total, settotal] = useState(10);

    useEffect(() => {
        Investors();
    },[]);

    const Investors = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/employess")
            .then(function (response) {
                setlist({...response.data})
                settotal(response.data.total)
            })
            .catch(function (error,response) {
                console.log("error",error)
            });

    };
    const selectRow = {
        mode: "checkbox",
        clickToSelect: false
    };

    const activeusersfu=(id,status='active')=>{
        axios.get(process.env.REACT_APP_BASE_URL+"auth/activeusers?id="+id+"&status="+status)
            .then(function (response) {
                Investors()
            })
            .catch(function (error,response) {
            });
    }


    const columns = [

        {
            'text': "Name",
            'dataField': "name",
            'sort': true
        },
        {
            'text': "Address",
            'dataField': "address",
            'sort': false,
            formatter: (cell,row) => `${row.address+', '+row.city+',\n'+row.state+', '+row.zipcode}`
        },
        {
            'text': "Email",
            'dataField': "email",
            'sort': true
        },
        {
            'text': "Last 4 of SSN",
            'dataField': "ssn",
            'sort': true,
            formatter:  (cell) => cell.substr(- 4)
        },
        {
            'text': "Status",
            'dataField': "status",
            'sort': false,
            formatter: rankFormatter,
        },
        {
            'text': "Action",
            'dataField': "action",
            'sort': true,
            formatter: Actionbuttons
        }
    ];

    function rankFormatter(cell, row, rowIndex, formatExtraData) {
        return (
            row.status == 'active'?
                <span onClick={()=>activeusersfu(row.id,row.status=='active'?'dactive':'active')} className=" pointercss badge badge-pill badge-soft-success font-size-14">{row.status=='active'?'Approve':'Reject '}</span>
                :
                <span onClick={()=>activeusersfu(row.id,row.status=='active'?'dactive':'active')} className=" pointercss badge badge-pill badge-soft-danger font-size-14">{row.status=='active'?'Approve':'Reject '}</span>

        ); }


    function Actionbuttons(cell, row, rowIndex, formatExtraData) {
        return (
            <ul className="list-inline font-size-20 contact-links mb-0">
                <li className="list-inline-item px-2  ">
                    <Link to="#" id={"message" + row.id}>
                        <i className="bx bx bx-edit-alt text-info"></i>
                        <UncontrolledTooltip placement="top" target={"message" + row.id}>
                            Edit
                        </UncontrolledTooltip>
                    </Link>
                </li>
                <li className="list-inline-item px-2 text-danger">
                    <Link to="#" id={"profile" + row.id}>
                        <i className="bx bx bx-x text-danger"></i>
                        <UncontrolledTooltip placement="top" target={"profile" + row.id}>
                            Delete
                        </UncontrolledTooltip>
                    </Link>
                </li>
            </ul>
        ); }





    const pageListRenderer = ({
                                  pages,
                                  onPageChange
                              }) => {
        const pageWithoutIndication = pages.filter(p => typeof p.page !== 'string');
        return (
            <div>
                {
                    pageWithoutIndication.map(p => (
                        <button className="btn btn-success" onClick={ () => onPageChange(p.page) }>{ p.page }</button>
                    ))
                }
            </div>
        );
    };


    return (
        <React.Fragment>
            <div className="page-content">

                <div className="container-fluid">
                    <Breadcrumbs title="Tables" breadcrumbItem="Employee"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <CardTitle>Employee</CardTitle>
                                    <BootstrapTable
                                        striped
                                        hover
                                        keyField="dn"
                                        data={list.data||[]}
                                        columns={columns}
                                        pagination={ paginationFactory()}
                                    />

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default DatatableTables;

