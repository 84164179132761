import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle,FormGroup,Label,Input, CardSubtitle, UncontrolledTooltip, Modal} from "reactstrap";
import Footer from "../../layout/footer";
import Mainsidebar from "./mainsidebar";
import Iframe from 'react-iframe'
import HeaderNew from './headerNew'
import '../../assets/css/style.css'

import '../../assets/css/home.css'



const axios = require('axios');

const SinglePortfolio = (props) => {

    const [Contact, setContact] = useState(false);
    const [single, setsingle] = useState(false);
    const [loader, Setloader] = useState(true);
    const [model, setmodel] = useState(false);

    let {id} = useParams();

    useEffect(() => {
        getdatatoshow()
    }, []);

    const getdatatoshow = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/getportfoliodetail/"+id)
            .then(function (response) {
                if(response.data.error == 0)
                    setContact(response.data.list)
                    setsingle(response.data.single)
            })
            .catch(function (error,response) {
                console.log("error",error)
            })
            .then(function () {
                Setloader(false)
            });

    };
    var sectionStyle = {
        backgroundImage: `url(${Contact.url})`
    };

    return (
        <>
            <HeaderNew homepage={false}/>
            <section className="slider-section about-banner" alt="about-banner-image">
                <img src={single.url}/>
                <h2 className="text-center">{single.address1} , {single.address2}</h2>
		    </section>

            {/* single portfolio */} 

            <section className="single-portfolio">
            <div className="container">
                {/* */}
                <div className="lending-header">
						
                        <Modal
							centered={model != false?true:false}
							isOpen={model}
							size="lg"
							toggle={()=>{setmodel(false)}}
						>
							<div className="modal-body">
								<Row>
									<Col className="col-12">
										<Iframe url={process.env.REACT_APP_BASE_URL+"portfogalleryhtml/"+model}
												width="100%"
												height="500px"
												id="myId"
												className="myClassname"
												display="initial"
												position="relative"/>
									</Col>

								</Row>

							</div>
						</Modal>

                    </div>

                {/* */}
                <div className="row">
                    <div className="col-md-9">
                        <div className="leftblock-content">
                           
							<div className="rowss">

                            {Object.entries(Contact|| {}).map(function ([index,value]) {
														return <div className="img-box">
																<div className="link-box">
																	<a
                                                                        className="fancybox fancybox.iframe portfolio-image portfolio-image"
                                                                        rel="group1"
                                                                        data-fancybox data-type="iframe"
                                                                        data-src={process.env.REACT_APP_BASE_URL+"portfogalleryhtml/"+value.id}
                                                                        href="javascript:;"
															       >   
																		<img src={value.url} />
																	</a>
																</div>
															</div>
													})}

								
							</div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="rightblock-content">
                            <h2>PROPERTY DETAILS</h2>
                            <div className="details">
                                <p><strong>Location:</strong><br/>{single.address2}</p>
                                <p><strong>Acquisition Date:</strong><br/>{single.date}</p>
                                <p><strong>Asset Type:</strong><br/>{single.type}</p>
                                <p><strong>Size:</strong><br/>{single.size}</p>
                                <p><strong>Opportunity Type:</strong><br/>{single.opportunity}</p>
                            </div>
                            <Link to="/portfolio" className="btn btn-info">BACK TO PORTFOLIO </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>  


            {/* single portfolio */} 

            <Footer/>

        </>
    )
};


export default SinglePortfolio ;
