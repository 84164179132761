import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle,FormGroup,Label,Input, CardSubtitle, UncontrolledTooltip, Modal} from "reactstrap";
import Footer from "../../layout/footer";
import Mainsidebar from "./mainsidebar";
import 'react-fancybox/lib/fancybox.css'
import '../../assets/css/home.css'
import Iframe from 'react-iframe'
import NumberFormat from 'react-number-format';
const axios = require('axios');
const Listingslist = () => {

	const [List, SetList] = useState(false);
	const [URL, Seturl] = useState(false);
	const [loader, Setloader] = useState(true);


	let {type,location} = useParams();


	useEffect(() => {
		getdatatoshow()
	}, []);

	const getdatatoshow = () => {
		axios.get(process.env.REACT_APP_BASE_URL+"auth/listings-listfind",{
			params: {
				type: type,
				location: location,
			}
		})
			.then(function (response) {
				if(response.data.error == 0)
					SetList(response.data.list)
				     Seturl(response.data.url)
			})
			.catch(function (error,response) {
				console.log("error",error)
			}) .then(function () {
			Setloader(false)
		});

	};

	const handleClose=()=>{
		return  true;
	}

	return (
		<>

			<div className="fixed-top-1">
				<Mainsidebar homepage={false} expanded = {false}/>
			</div>
			{loader === true?<div id="loaderdiv"><div id="loader"></div></div>:''}
			<section id="hero1">
				{/* top item start */}
				<div className="container space-otherpage-top">
					<div className="lending-header">
						<h1 className="lending-header-h1">LISTINGS</h1>
					</div>
					<div className="row pt-5 pb-5 listings-filter equal">

						{Object.entries(List|| {}).map(function ([index,value]) {
							return <div className="col-lg-4 col-md-6 col-sm-12 my-2 ">

								<Link to={"/listings-detail/"+value.id} className="card">
									<img
										className="card-img-top custome-Listingslist-image"
										src={JSON.parse(value.images)[0]?URL+JSON.parse(value.images)[0]:URL+"/homebox/HccJlLBw0R.jpg"}
										alt="Card image cap"
										onerror="this.onerror=null;this.src='https://placeimg.com/200/300/animals';"
									/>


									<div className="card-body">
										<div className="row align-items-start">
											<div className="col-sm-8">
												<h5 className="text-dark font-size-17 font-weight-bolder font-weight-bold"><b>{value.address}</b></h5>
												<h4 className="text-dark font-size-15 font-weight-bold">{value.city}</h4>
												<h4 className="text-dark font-size-15 font-weight-bold">{value.state}</h4>
											</div>
											<div className="col-sm-4">
												{/*<h5 className="text-dark font-size-13 font-weight-bold">{value.area}</h5>*/}
												{/*<h5 className="text-dark font-size-13 font-weight-bold">{value.lot_size}</h5>*/}
												<h5 className="text-dark font-size-15 font-weight-bold">Bedrooms: {value.bedrooms}</h5>
												<h4 className="text-dark font-size-15 font-weight-bold">Bathrooms: {value.bathrooms}</h4>
												<h4 className="text-dark font-size-15 font-weight-bold">{value.type}</h4>
											</div>
										</div>

									</div>
									<div className="card-footer text-muted">
										<div className="row align-items-end">
											<div className="col-sm-12">
												<h6 className="text-dark text-center font-size-20 font-weight-bold ">Price</h6>
												<h6 className="text-dark text-center font-size-17 font-weight-bold "><NumberFormat value={value.price} displayType={'text'} thousandSeparator={true} prefix={'$'} /><span style={{"opacity":0}} >|</span> </h6>
											</div>
										</div>
									</div>
								</Link>
							</div>
						})
						}
						{List.length == 0?

							<div className="col-sm-12 ">
								<hr/>
								<h4 className="text-dark text-center font-size-30 font-weight-bold">No Result  Found...</h4>
								<hr/>
							</div>:""
						}
					</div>
				</div>
			</section>
			<Footer/>

		</>
	)
};


export default Listingslist ;
