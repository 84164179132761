import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle,FormGroup,Label,Input, CardSubtitle, UncontrolledTooltip, Modal} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../../assets/scss/datatables.scss";
import {confirmAlert} from "react-confirm-alert";
const axios = require('axios');


const Training = () => {
    const [list, setlist] = useState([]);
    const [count, setCount] = useState(0);
    const [model, setModel] = useState(false);
    const [pdf, Setpdf] = useState({});
    const [boxerror, setboxerror] = useState({});

    useEffect(() => {
        Pages();
    },[]);

    const Pages = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/pdf/PDflist/training")
            .then(function (response) {
                if(response.data.error == 0)
                    setlist(response.data)
            })
            .catch(function (error,response) {
                console.log("error",error)
            });

    };

    const manageModel = (value)=>{
        setModel(value)
        setboxerror({})
    }
    const popupmodels= (id,value)=>{

        axios.get(process.env.REACT_APP_BASE_URL+"auth/pdf/pdffind/"+id)
            .then(function (response) {
                Setpdf({...response.data.list})
                manageModel(value);
            })
            .catch(function (error,response) {
            });

    };


    const columns = [


        {
            'text': "Title",
            'dataField': "title",
            headerAlign: 'center','align': 'center',
        },
        {
            'text': "",
            'dataField': "url",
            formatter: Imageset,
            headerAlign: 'center','align': 'center',
        },


    ];



    function Imageset(cell, row, rowIndex, formatExtraData) {
        return (
            <a href={cell} className="btn-rounded waves-effect  btn btn-dark btn-sm px-3 font-size-12" target="_blank">View File</a>
        ); }



    const sizePerPageRenderer = ({
                                     options,
                                     currSizePerPage,
                                     onSizePerPageChange
                                 }) => (
        <div className="btn-group border" role="group">
            {
                options.map(option => (
                    <button
                        key={ option.text }
                        type="button"
                        onClick={ () => onSizePerPageChange(option.page) }
                        className={ `btn ${currSizePerPage === `${option.page}` ? 'btn-secondary' : 'btn-warning'}` }
                    >
                        { option.text }
                    </button>
                ))
            }
        </div>
    );

    const options = {
        sizePerPageRenderer
    };



    return (
        <React.Fragment>
            <div className="page-content">

                <div className="container-fluid">
                    <Breadcrumbs title="Tables" breadcrumbItem="Training"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="text-center">
                                    <CardTitle className="text-center">Training</CardTitle>

                                    </div>
                                    <BootstrapTable
                                        striped
                                        hover
                                        keyField="dn"
                                        data={list.list||[]}
                                        columns={columns}
                                        pagination={ paginationFactory(options)}
                                    />
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Training;

