import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
const headerNew = (props) => {

	//console.log(props);
	const cl = props.hasOwnProperty('contact')?'contactts':'';
	//console.log(cl);
    
return (
        <>
            <header className={cl}>
		    <div className="container">
			    
				<nav className="navbar navbar-expand-lg">
					<Link className="navbar-brand" to="/">
						<img className="white_logo desktop_logo" src="/WEB-logo-footer4.png" alt="Phantom Partners" title="Phantom Partners"/>
						<img className="dark_logo desktop_logo" src="/WEB-logo-footer3.png" alt="Phantom Partners" title="Phantom Partners"/>
					</Link>
					  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					  </button>

					  <div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav ">
						  <li className="nav-item active">
							<Link className="nav-link" to="/about">About Us</Link>
						  </li>
						 {/* <li className="nav-item">
							<Link className="nav-link" to="http://www.phantomrealtynyc.com">Listings</Link>
						  </li> */}

						  <li className="nav-item">
							<Link className="nav-link" to="/lending">Lending</Link>
						  </li>

						  <li className="nav-item">
							<Link className="nav-link" to="/invest-with-us">Invest With Us</Link>
						  </li>

						  <li className="nav-item">
							<Link className="nav-link" to="/portfolio">Portfolio</Link>
						  </li>

						  <li className="nav-item">
							<Link className="nav-link" to="/contact">Contact</Link>
						  </li>	

						  <li className="nav-item">
							<Link className="nav-link" to="/login">Investor Login</Link>
						  </li>
						
						</ul>
					  </div>
				</nav>
			</div>
		</header>

        </>
    );
};


export default headerNew;
