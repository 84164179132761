import React, { useState, useEffect } from "react";

import { Row, Col, Card, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

import avatar1 from "../../assets/images/users/avatar-1.jpg";
import profileImg from "../../assets/images/profile-img.png";
import toastr from "toastr";

const axios = require('axios');

const WelcomeComp = (props) => {

    const usersession = sessionStorage.getItem('authUser');
    const user = JSON.parse(usersession);

    const [avatar, setavatar] = useState("");
    const [Name, Setname] = useState("");

    useEffect(() => {

        setTimeout(()=>{
            if(user && user.user)
            {
                setavatar(user.user.image);
                Setname(user.user.name);
            }
            else
                setavatar(avatar1);
        },0)

    },[]);



    const onFileChange = event => {
        toastr.info('image uploading..... ');
        const formData = new FormData();
        formData.append('image', event.target.files[0], event.target.files[0].name);
        axios.post(process.env.REACT_APP_BASE_URL + "auth/setptofile", formData,{headers: {'Authorization': `${user.access_token}`}})
            .then(function (response) {
                setavatar(response.data.image);
                user.user.image = response.data.image;
                sessionStorage.setItem("authUser", JSON.stringify(user));
                toastr.clear()
                toastr.success('profile updated successfully');

            })
            .catch(function (e) {
                toastr.clear()
                Object.entries(e.response.data.errors).map(([key,i]) => {
                     toastr.error(i[0]||'');
                })
            });
    };


        return (
            <React.Fragment>
                <Card className="overflow-hidden">
                    <div className="bg-soft-primary">
                        <Row>
                            <Col xs="7">
                                <div className="text-primary p-3">
                                    <h5 className="text-primary">Welcome Back!</h5>
                                    <p>Phantom Partners Employee Dashboard</p>
                                </div>
                            </Col>
                            <Col xs="5" className="align-self-end">
                                <img   src={profileImg} alt="" className="img-fluid-home" />
                            </Col>

                        </Row>
                    </div>
                    <CardBody className="pt-0 afterprofiletext">
                        <Row>
                            <Col sm="4">
                                <div className="avatar-md profile-user-wid mb-4 pointercss">
                                    <label for="file-input">
                                    <img style={{ width: 100, height: 100,maxWidth:100 }} src={avatar} alt="" className="img-thumbnail rounded-circle pointercss" />
                                    </label>
                                    <form>
                                        <input type="file" onChange={(event => onFileChange(event))} name="file" id="file-input" className="hidefileuplodeonproile"/>
                                    </form>
                                </div>
                            </Col>
                            <Col sm="8">
                                <div className="pt-4">
                                        <div className="media">
                                            <div className="media-body"><p style={{ textTransform: "capitalize" }} className="text-muted font-weight-medium   font-size-15 font-weight-bold title">
                                                {Name}</p>
                                            </div>
                                        </div>
                                </div>
                            </Col>

                        </Row>
                    </CardBody>

                </Card>
            </React.Fragment>
        );

}

export default WelcomeComp;
