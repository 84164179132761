import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Footer from "../../layout/footer";
import Mainsidebar from "./mainsidebar";
import HeaderNew from './headerNew'
import classnames from 'classnames';
import '../../assets/css/style.css'
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'



//import '../../assets/css/home.css'



const axios = require('axios');
const NewLogin = (props) => {

    const [tab, settab] = useState('Login');
    const [signup, setSignup] = useState({});
    const [signupsuccess, setsignupsuccess] = useState('');
    const [signuperror, setsignuperror] = useState({});
    const [regitype, setregitype] = useState('employee');
    const [logintab, setlogintab] = useState('1');
    const [investor, setInvestor] = useState({});
    const [login, setLogin] = useState({});
    const [Contact, setContact] = useState(false);
    const [loader, Setloader] = useState(true);
    let history = useHistory();


    useEffect(() => {
        window.scrollTo(0, 0)
        getdatatoshow()
    }, []);

    const Employeelogin = (event) =>{
        event.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL+'auth/login', {...login,'type':'employee'})
            .then(function (response) {
                sessionStorage.setItem("authUser", JSON.stringify(response.data));
                history.push('/employees');
            })
            .catch(function (error,response) {
                toastr.error(error.response.data.message);
            });

    }

    const InvestorLogin = (event) =>{
        event.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL+'auth/login', {...investor,'type':'Investor'})
            .then(function (response) {
                sessionStorage.setItem("authUser", JSON.stringify(response.data));
                history.push('/investors');
            })
            .catch(function (error,response) {
                toastr.error(error.response.data.message);
            });

    }

    const Signupusers = (event) =>{
        event.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL+'auth/signup', {...signup,'type':regitype})
            .then(function (response) {
                setSignup({})
                setsignupsuccess(response.data.message);
                setsignuperror({})
                setregitype(regitype);
                //setRegimodel(false)
                //setModal(true)
            })
            .catch(function (error,response) {
                setsignuperror(error.response.data.errors)
            });
    }
    
    const getdatatoshow = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/aboutusget")
            .then(function (response) {
                if(response.data.error == 0)
                    setContact(response.data.list)
            })
            .catch(function (error,response) {
                console.log("error",error)
            })
            .then(function () {
                Setloader(false)
            });

    };
    var sectionStyle = {
        backgroundImage: `url(${Contact.image})`
    };

    return (
        <>
            <HeaderNew homepage={false} contact={true}/>
            <section className="slider-section about-banner" alt="about-banner-image">
                {/* <img src="/about-banner.jpg"/>
                <h2 className="text-center">{tab =="Login"?"Login":"Register"}</h2> */}
		    </section>

            <div className="container login-tp-sec">
		        <div className="row">
				    <div className="col-sm-8">
				    <div className="main-inner-log-sign-page">
					<div className="login">
						<div className="content-body">
							<div className="header">
								<h2 className="text-center">Welcome to Phantom Capital, LLC</h2>
								<p className="text-center">Please sign in for access</p>
							</div>
							<form method="post"  onSubmit={(e)=>{InvestorLogin(e)}}>
								<div className="form-group">
									<label for="mail">Email address</label>
									<input type="email" onBlur={(event)=>{setInvestor({ ...investor, ['email']: event.target.value })}} defaultValue={investor.email} className="form-control" placeholder="Enter Email Address"/>                   
								</div>
								<div className="form-group">
									<label for="pwd">Password</label>
									<input type="password" onKeyDown={(event)=>{setLogin({ ...login, ['password']: event.target.value })}} onBlur={(event)=>{setInvestor({ ...investor, ['password']: event.target.value })}} defaultValue={investor.password} className="form-control" placeholder="Password"/>
								</div>
								<div className="form-group form-check">
									<label className="form-check-label">
										{/*<input className="form-check-input" type="checkbox" name="mob"/>Remember me on this computer */}
									</label>
								</div>
								<div className="text-center btn-block">
									<input type="submit" className="btn btn-info btn-block" value="Sign in"/>
								</div>
								<div className="text-center link-block">
								  
								</div>
								<div className="login-hints text-center">
        
									{/*	<a href="#password_reset_tab" role="tab" data-toggle="tab">Forgot your password?</a>
									    <span className="text-muted">∙</span>
                                        <a href="" target="_blank" className="format-addr" data-suffix="clearcapllc.com" data-prefix="srotsevni">Need help?</a> */}
									
								</div>
								<div className="panel panel-default">
                            <div className="panel-body">
                                <div className="login-message">
							
									<div style={{ textAlign: 'center' }}><span style={{ fontSize: 12 }}><span style={{ fontfamily: 'Calibri,sans-serif'}}>Welcome to the Phantom Capital, LLC Investor Portal! </span></span></div>

									<div style={{ textAlign: 'center' }}><span style={{ fontSize: 12 }}><span style={{ fontfamily: 'Calibri,sans-serif'}}><span style={{ backgroundColor: 'white' }}><span style={{ color: '#333333' }}>This restricted area of our website is for current Investors of Phantom Capital, LLC. If you need help accessing your account, or would like to create an account, please contact us at contact@phantompartner.com or 718-215-1000&nbsp;.</span></span></span></span></div>

						
								
							
								
							
								</div>
                            </div>
                        </div>
							</form>
			
						</div>
					</div>
				</div>
				</div>
				<div className="col-md-4 hidden-xs hidden-sm lft-lg-img">
				    <img src="/login-img1.png"/>
					<img src="/login-img2.png"/>
					<img src="/login-img3.png"/>
				</div>
				</div>
			</div>


              {/* ---------  */}  
              
            <Footer/>

        </>
    )
};


export default NewLogin ;
