import React, { useState, useEffect } from "react";
import { Link,useParams } from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle,FormGroup,Label,Input, CardSubtitle, UncontrolledTooltip, Modal} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../../assets/scss/datatables.scss";
import {confirmAlert} from "react-confirm-alert";
import Select from "react-select";
import Slider from "react-rangeslider";
import "react-rangeslider/lib/index.css";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

import { css } from "@emotion/core";
import ClipLoader from "react-spinners/ClipLoader";
const axios = require('axios');


const CurrentProperties = () => {
    const [label, setlist] = useState({});
    const [labels, setlabels] = useState({});
    const [data, SetData] = useState({});
    const [boxerror, setboxerror] = useState({});
    const usersession = sessionStorage.getItem('authUser');
    const [model, setModel] = useState(false);
    const user = JSON.parse(usersession);


    let { id } = useParams();

    useEffect((props) => {
        console.log('props',id);
        Pages();
        setlabels( {
            1:"Submitted",
            2:"In process",
            3:"Missing Documents",
            4:"Pending Appraisal",
            5:"Offer Negotiation",
            6:"Short Sell Approval",
            7:"Title Clearance/Schedule a Closing",
            8:"Completed",
        });
    },[]);
    const extra_age = value => labels[value];

    const Pages = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/current-propertieslist/"+id)
            .then(function (response) {
                if(response.data.error == 0)
                    setlist(response.data.list)
            })
            .catch(function (error,response) {
                console.log("error",error)
            });

    };

    const manageModel = (value)=>{
        setModel(value)
    }


    const onFileChange = event => {
        SetData({...data,'file':event.target.files[0]})
    };

    const savepost=()=>{

        const formData = new FormData();

        if(data.file)
            formData.append("file",data.file,data.file.name);
            formData.append("title",data.title);
            formData.append("id",id);


        axios.post(process.env.REACT_APP_BASE_URL+"auth/current-Properties-file", formData,{headers: {'Authorization': `${user.access_token}`}})
            .then(function (response) {
                Pages();
                setModel(false);
                setboxerror({});
                SetData({});
            })
            .catch(function (error,response) {
                setboxerror(error.response.data.errors)
            });
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Tables" breadcrumbItem="View Status Details"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="text-center" id="custom-ragnge-slider">
                                        <Col md={12}>
                                            <div className={"py-3 "+ ' CurrentPropertiescustom CurrentPropertiescustom'+label.status}>
                                                {/*<h5 className="font-size-14 mb-3 mt-0">*/}
                                                {/* Custom Values */}
                                                {/*</h5>*/}
                                                <Slider
                                                    value={label.status}
                                                    min={1}
                                                    max={8}
                                                    format={extra_age}
                                                    labels={labels}
                                                    tooltip={false}
                                                    className="custom-ragnge-slider"
                                                    onChange={value => {

                                                    }}
                                                />


                                            </div>
                                        </Col>
                                        {label.status == 3?
                                        <Col md={12}>
                                            <div className="p-3">

                                                <div className="form-group ">

                                                    <Col className="col-12 mt-5">
                                                        <div className="form-group mt-3">
                                                            <FormGroup>
                                                                <button onClick={()=>{manageModel(true)}} type="button" className="btn  btn-dark w-md center-block ">Add File </button>
                                                            </FormGroup>
                                                            <div className="table-responsive">
                                                            <table className="table table-centered table-bordered table-sm    w-50 center-block mx-auto ">
                                                                <thead className="thead-light">
                                                                <tr>
                                                                    <td style={{width: "100%"}} colSpan="2">
                                                                        <p className="mb-0 font-size-18 font-weight-bold ">File list</p>
                                                                    </td>
                                                                </tr>
                                                                </thead>
                                                                <tbody>

                                                                {label.data?Object.entries(JSON.parse(label.data).reverse()|| {}).map(function ([index,value]) {
                                                                    return <tr>
                                                                        <td style={{width: "70%"}}>
                                                                            <p className="mb-0 font-size-14">{value.title}</p>
                                                                        </td>
                                                                        <td style={{width: "30%"}}>
                                                                            <a href={label.baseurl+value.file} type="button"
                                                                               className="btn btn-dark btn-sm w-md center-block font-size-14 "> File </a>
                                                                        </td>
                                                                    </tr>
                                                                }):''}
                                                                </tbody>
                                                            </table>
                                                            </div>


                                                            <Modal
                                                                centered={true}
                                                                size="lg"
                                                                isOpen={model}
                                                                toggle={()=>{manageModel(false)}}
                                                                className="current-properties-custom">

                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="modal-title mt-0"
                                                                        id="myLargeModalLabel"
                                                                    >
                                                                        Missing file
                                                                    </h5>
                                                                    <button
                                                                        onClick={() =>
                                                                            manageModel(false)
                                                                        }
                                                                        type="button"
                                                                        className="close"
                                                                        data-dismiss="modal"
                                                                        aria-label="Close"
                                                                    >
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body ">
                                                                    <form>

                                                                        <div className="form-group text-left">
                                                                            <FormGroup>
                                                                                <Label htmlFor="formmessage">Title :</Label>
                                                                                <Input
                                                                                    type="title"
                                                                                    id="formmessage"
                                                                                    className="form-control"
                                                                                    defaultValue={data.title}
                                                                                    onBlur={(event)=>{SetData({ ...data, ['title']: event.target.value })}}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="form-group text-left">
                                                                            <FormGroup>
                                                                                <Label htmlFor="formmessage ">File:</Label>
                                                                                <input type="file"
                                                                                       id="formrow-firstname-input"
                                                                                       className="form-control-file"
                                                                                       onChange={onFileChange}
                                                                                />
                                                                            </FormGroup>

                                                                        </div>

                                                                        <div className="text-center">
                                                                            <button onClick={()=>{savepost()}} type="button" className="btn btn-primary w-md center-block">Submit
                                                                            </button>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </Modal>

                                                        </div>
                                                    </Col>

                                                </div>
                                            </div>
                                        </Col>
                                            :''}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CurrentProperties;

