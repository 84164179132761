import React, {useEffect, useState} from 'react';
import '../assets/css/demo.css'
import NumberFormat from 'react-number-format';
import image from '../assets/img/about-img-bg.png';
const axios = require('axios');

const Map = (props) => {


    const [Contact, setContact] = useState({});
    useEffect(() => {
        getdata()
    }, []);


    const getdata = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/businessinfoget")
            .then(function (response) {
                if(response.data.error == 0)
                    setContact(response.data.list)
            })
            .catch(function (error,response) {
                console.log("error",error)
            });

    };
    var data1 = {...Contact[1]}
    var data2 = {...Contact[2]}
    var data3= {...Contact[3]}
    var data4 = {...Contact[4]}
    var data5 = {...Contact[5]}

    return (
        <>


            <div className="maindiv" style={{backgroundImage: `url(${image})`}}>
                <div className="flex-container   ">
                    <div className="flex-container-div-1">
                        <h3 className="dark-blue">{data1.text}</h3>
                        <h5 className="dark-blue">{data1.text2}</h5>

                    </div>
                    <div className="flex-container-div-1">
                        <h3 className="blue"><NumberFormat value={data2.text} displayType={'text'} thousandSeparator={true} /></h3>
                        <h6 className="blue">{data2.text2}</h6>
                    </div>
                </div>
                <div className="flex-container">
                    <div className="flex-container-div-2">
                        <h3 className="blue spaces-h3 h3-text">{data3.text} </h3>
                        <h6 className="blue h5-text-light">{data3.text2}</h6>
                    </div>
                </div>
                <div className="flex-container">
                    <div className="flex-container-div-3 float-right">
                        <h3 className="blue "><NumberFormat value={data4.text} displayType={'text'} thousandSeparator={true} /></h3>
                        <h5 className="blue ">{data4.text2}</h5>
                        <h5 className="blue ">{data4.text3}</h5>
                    </div>
                    <div className="flex-container-div-3 float-left">
                        <h6 className="dark-blue ">{data5.text}</h6>
                        <h3 className="dark-blue " style={{whiteSpace: 'nowrap'}}>{data5.text2} </h3>
                        <h6 className="dark-blue ">{data5.text3}</h6>
                    </div>
                </div>

            </div>

            <br/>
            <br/>


        </>
    )
};


export default Map;
