import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import Footer from "../../layout/footer";
import HeaderNew from './headerNew'
import Mainsidebar from "./mainsidebar";

import '../../assets/css/home.css'

const axios = require('axios');

const Investwithus = (props) => {

    const [Contact, setContact] = useState(false);
    const [tab, settab] = useState('Acquisitions');
    const [Images, setimages] = useState([]);
    const [details, setdetails] = useState([]);
    const [loader, Setloader] = useState(true);


    useEffect(() => {
        window.scrollTo(0, 0)
        getdatatoshow()
    }, []);

    const getdatatoshow = () => {
        axios.get(process.env.REACT_APP_BASE_URL+"auth/investwithusget")
            .then(function (response) {
                if(response.data.error == 0)
                {
                    setContact(response.data.list)
                    setimages(response.data.images)
                    setdetails(response.data.details)
                }
            })
            .catch(function (error,response) {
                console.log("error",error)
            }).then(function () {
            Setloader(false)
        });

    };
    var sectionStyle = {
        backgroundImage: `url(${Contact.image})`
    };

    return (
        <>

        <HeaderNew homepage={false}/>
        <section className="slider-section about-banner" alt="invest-banner-image">
		    <img src={Contact.image}/>
            <h2 className="text-center">Investment With Us</h2>
		</section>

        <section className="about-content">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="content">
                            <p>{Contact.discription}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="investment-tab">
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <div className="tab-list">
                            <ul className="nav nav-tabs">
                        {
                            Object.entries(details||{}).map(function ([index, value]) {
                                return <li className="nav-item">
                                <a className="nav-link active" data-toggle="tab" onClick={()=>{settab(value.type)}} >{value.type}</a>
                                </li>
                            })
                        }

                            </ul>
                        </div>
                    </div>
                    <div className="col-md-7">
                        <div className="tab-content">
                        {
                            Object.entries(details||{}).map(function ([index, value]) {
                                return <div>{tab == value.type && value != 0 ?
                                            <p>
                                            {Object.entries(JSON.parse(value.text) || {}).map(function ([index,value]) {
                                                return <p>{value}</p>
                                            })}
                                            </p>
                                            :''
                                        }
                                        </div>
                            })
                        }

                       
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <Footer/>

        </>
    )
};


export default Investwithus ;
