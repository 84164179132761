import React, {useEffect, useState, useRef} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle, CardText,  ColButton,Modal,Row, Col, CardBody,  Alert,Container,  ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from 'classnames';
import '../../assets/css/home.css'
import '../../assets/css/style.css'
import {loginSuccess} from "../../store/auth/login/actions";
import { Link, useHistory } from "react-router-dom";
import Footer from '../../layout/footer'
import Mainsidebar from '../../pages/home/mainsidebar'
import HeaderNew from './headerNew'
import Mapwithinfo from '../../components/map'
import toastr from 'toastr'
import BackgroundSlider from 'react-background-slider'
import RevSlider, { Slide, Caption } from 'react-rev-slider';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'toastr/build/toastr.min.css'

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)
const axios = require('axios');

const Home = (props) => {

    const usersession = sessionStorage.getItem('authUser');
    const user = JSON.parse(usersession);

    const [modalShow, setModalShow] = useState(false);
    const [slidetextShow, setslidetextShow] = useState(false);
    const [logintab, setlogintab] = useState('1');
    const [regimodel, setRegimodel] = useState(false);
    const [login, setLogin] = useState({});
    const [investor, setInvestor] = useState({});
    const [modal, setModal] = useState(false);
    const [regitype, setregitype] = useState('employee');
    const [signup, setSignup] = useState({});
    const [signuperror, setsignuperror] = useState({});
    const [boxlist, setboxlist] = useState([]);
    const [signupsuccess, setsignupsuccess] = useState('');
    const [scroll, setscroll] = useState(true);
    const [images, setimages] = useState([]);
    const [description, setdescription] = useState([]);
    const [stitle, settitle] = useState([]);
    const [is_imagesloaded, setimagesloaded] = useState(false);
    let history = useHistory();
    //creates a reference for your element to use
    

   const myRef = useRef(null)
   const executeScroll = () => scrollToRef(myRef)
    

    const {
        buttonLabel,
        className
    } = props;

    var dataText = ['<h1>who we are</h1><p>Phantom Capital is a Private Real Estate Investment Firm</p><a href="#heroen">learn more</a>','<h1>we are best</h1><p>Phantom Capital is a Private Real Estate Investment Firm</p><a href="#heroen">learn more</a>','<h1>Great Work</h1><p>Phantom Capital is a Private Real Estate Investment Firm</p><a href="#heroen">learn more</a>'];
    useEffect(() => {
        getbox();
        //setInterval(function(){ console.log("Hello"); }, 3000); 
        if (slidetextShow === true) {
            StartTextAnimation(0);  
        }
        window.addEventListener('scroll', scroolleventshandleScroll);

        if (regimodel === false) {

            setTimeout(()=>{
                document.body.style.paddingRight = "1px";
            },400)
        }
    },[regimodel,scroll,slidetextShow]);


   const  StartTextAnimation = (i)=> {
    if (typeof description[i] == 'undefined'){
        StartTextAnimation(0);
    }
    else{
        // check if dataText[i] exists
        if (i < description[i].length) {
            //document.getElementById('slidetextdynamic').style.opacity = 0.6;
            var atext = '<h1>'+stitle[i]+'</h1>';
            atext +='<p>'+ description[i] + '</p>';
            atext += '<a href="#heroen">learn more</a>';
            document.getElementById('slidetextdynamic').innerHTML= atext; 
            //document.getElementById('slidetextdynamic').style.opacity = 1;
            setTimeout(function() {   
                StartTextAnimation(i + 1);
            }, 6050);

        }
    }
}

   const scroolleventshandleScroll = (event)=> {
    console.log('itemTranslate',window.pageYOffset);

       if(window.pageYOffset >= 300)
       {
           setscroll(false);
           console.log(scroll,111)
       }
       else
       {
           setscroll(true);
           console.log(scroll,200)
       }
    }



    const tabset = tab => {
        setlogintab(tab);
    }

    const toggle = tab => {
        setModal(!modal);
    }
    const regiclose = tab => {
        setRegimodel(!regimodel);
    }


    const Employeelogin = (event) =>{
        event.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL+'auth/login', {...login,'type':'employee'})
            .then(function (response) {
                sessionStorage.setItem("authUser", JSON.stringify(response.data));
                history.push('/employees');
            })
            .catch(function (error,response) {
                toastr.error(error.response.data.message);
            });

    }

    const InvestorLogin = (event) =>{
        event.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL+'auth/login', {...investor,'type':'Investor'})
            .then(function (response) {
                sessionStorage.setItem("authUser", JSON.stringify(response.data));
                history.push('/investors');
            })
            .catch(function (error,response) {
                toastr.error(error.response.data.message);
            });

    }

    const Signupusers = (event) =>{
        event.preventDefault();
        axios.post(process.env.REACT_APP_BASE_URL+'auth/signup', {...signup,'type':regitype})
            .then(function (response) {
                setSignup({})
                setsignupsuccess(response.data.message);
                setsignuperror({})
                setregitype(regitype);
                setRegimodel(false)
                setModal(true)
            })
            .catch(function (error,response) {
                setsignuperror(error.response.data.errors)
            });
    }

    const getbox = () => {
        //setimages(response.data.slider);
        axios.get(process.env.REACT_APP_BASE_URL+"auth/homeboxlist")
            .then(function (response) {
                if(response.data.error == 0)
                    setboxlist(response.data.list)
                    setimages(response.data.slider)
                    settitle(response.data.title)
                    setdescription(response.data.desc)
                    setimagesloaded(true)
                    setslidetextShow(true)
            })
            .catch(function (error,response) {
                console.log("error",error)
            });

    };

    const config = {
        delay:9000,
        startwidth:1170,
        startheight:500,
        hideThumbs:10,
        fullWidth:"on",
        forceFullWidth:"on"
    };


    return (
      <>
         {/* <header id="header" className="fixed-top-class">


              <div className="container-fluid d-flex align-items-center justify-content-between" style={{paddingLeft:'13%',paddingRight: '13%'}}>
                  <div>
                      <h1 className="logo">
                          <Link to="/">
                              <img src="https://i.postimg.cc/4yWPZ0FW/WEB-logo.png"  />
                          </Link>
                      </h1>
                  </div>
                  <div className="one">
                      {console.log("user",user)}

                      {

                          user?
                              <Link to={'/dashboard'} className="get-started-btn scrollto logindata border-0" >
                                  Dashboard
                            </Link>
                              :
                              <button className="get-started-btn scrollto logindata border-0"  onClick={() => {setModal(true);setlogintab("1")}}>
                                  LOGIN
                              </button>
                      }



                      <Modal className="removeboderB" isOpen={modal}  toggle={toggle}  centered={true}  size="lg">
                          <ModalHeader toggle={toggle}> </ModalHeader>
                          <ModalBody>
                              <Nav tabs  className='nav-justified' style={{ padding: 0}}>
                                  <NavItem >
                                      <NavLink
                                          className={classnames({ active: logintab === '1' },'lopginmenul')}
                                          onClick={() => { tabset('1'); }}
                                      >
                                          Employee
                                      </NavLink>
                                  </NavItem>
                                  <NavItem>
                                      <NavLink
                                          className={classnames({ active: logintab === '2' },'lopginmenur')}
                                          onClick={() => { tabset('2'); }}
                                      >
                                          Investor
                                      </NavLink>
                                  </NavItem>
                              </Nav>
                              <TabContent activeTab={logintab }>
                                  <TabPane tabId="1">
                                      <Row>
                                          <Col sm="12">
                                              <div>
                                                  <p className="register-heading d-none" >
                                                      If you are an investor with Phantom Partners, please login here to access
                                                      your information. If you need assistance with your username or password,
                                                      please contact Investor Service at 855-PASSCO-5 (855-727-7265).
                                                  </p>
                                                  <p className="register-heading" >
                                                      Login here to access the Phantom Partners employees portal.
                                                  </p>

                                                  <div className=" register-form">
                                                      <div className="com-12">
                                                          <p className="text-danger font-size-15 "><b>{signupsuccess}</b></p>
                                                      </div>
                                                      <div className="col-md-12">
                                                          <form method="post"  onSubmit={(e)=>{Employeelogin(e)}}>
                                                              <div className="form-group">
                                                                  <input
                                                                      type="text"
                                                                      name="LGform1_user"
                                                                      className="form-control"
                                                                      placeholder="Email Address"
                                                                      required
                                                                      onBlur={(event)=>{setLogin({ ...login, ['email']: event.target.value })}}
                                                                      defaultValue={login.email}
                                                                      key='userlofin1'
                                                                  />
                                                              </div>
                                                              <div className="form-group">
                                                                  <input
                                                                      type="password"
                                                                      name="LGform1_user"
                                                                      className="form-control"
                                                                      placeholder="Password"
                                                                      required
                                                                      onKeyDown={(event)=>{setLogin({ ...login, ['password']: event.target.value })}}
                                                                      onBlur={(event)=>{setLogin({ ...login, ['password']: event.target.value })}}
                                                                      defaultValue={login.password}
                                                                      key='password120'
                                                                  />
                                                              </div>
                                                              <div className="text-right">
                                                                  <a href="ForgetPassword.php" className="btnForgetPwd" value="Login">
                                                                      Forget Password?
                                                                  </a>
                                                              </div>
                                                              <div className="one1">
                                                                  <button type="submit" className="get-started-btn scrollto text-white" >
                                                                      LOGIN →
                                                                  </button>
                                                              </div>
                                                              <div className="form-group-center">
                                                                  <p>
                                                                      Need an account?
                                                                      <a type="button" onClick={() => {setRegimodel(true);setregitype('employee');setModal(false)}} className="registerpopup text-primary pointercss px-1" data-toggle="modal">
                                                                          Register
                                                                      </a>
                                                                  </p>
                                                                 <br/>

                                                              </div>
                                                          </form>
                                                      </div>
                                                  </div>
                                              </div>
                                              <br/>
                                              <br/>
                                              <br/>
                                          </Col>
                                      </Row>
                                  </TabPane>
                                  <TabPane tabId="2">
                                      <Row>
                                          <Col sm="12">
                                              <div>
                                                  <p className="register-heading d-none" style={{ paddingTop: 25, width:"58%"}}>
                                                      Login here to access your clients' information. If you need assistance with
                                                      your username or password, please contact Investor Services at 855-PASSCO-5
                                                      (855-727-7265).
                                                  </p>
                                                  <p className="register-heading" >
                                                      If you are an investor with Phantom Partners, please login here to access your information.
                                                  </p>
                                                  <div className=" register-form">
                                                      <div className="com-12">
                                                          <p className="text-danger font-size-15"><b>{signupsuccess}</b></p>
                                                      </div>
                                                      <div className="col-md-12">
                                                          <form method="post" onSubmit={(e)=>{InvestorLogin(e)}}>
                                                              <div className="form-group">
                                                                  <input
                                                                      type="text"
                                                                      name="LGform2_user"
                                                                      className="form-control"
                                                                      placeholder="Email Address"
                                                                      onBlur={(event)=>{setInvestor({ ...investor, ['email']: event.target.value })}}
                                                                      defaultValue={investor.email}
                                                                      required
                                                                  />
                                                              </div>
                                                              <div className="form-group">
                                                                  <input
                                                                      type="password"
                                                                      name="LGform2_pwd"
                                                                      className="form-control"
                                                                      placeholder="Password*"
                                                                      onKeyDown={(event)=>{setLogin({ ...login, ['password']: event.target.value })}}
                                                                      onBlur={(event)=>{setInvestor({ ...investor, ['password']: event.target.value })}}
                                                                      defaultValue={investor.password}
                                                                      required
                                                                  />
                                                              </div>
                                                              <div className="text-right">
                                                                  <a href="ForgetPassword.php" className="btnForgetPwd" value="Login">
                                                                      Forget Password?
                                                                  </a>
                                                              </div>
                                                              <div className="one1">

                                                                  <button type="submit" className="get-started-btn scrollto text-white" >
                                                                      LOGIN →
                                                                  </button>
                                                              </div>
                                                              <div className="form-group-center">
                                                                  <p>
                                                                      Need an account?
                                                                      <a onClick={() => {setRegimodel(true); setregitype('investor');setModal(false)}}  className="registerpopup text-primary pointercss px-1" data-toggle="modal">
                                                                          Register
                                                                      </a>
                                                                  </p>
                                                                  <br/>
                                                                  </div>
                                                          </form>
                                                      </div>
                                                  </div>
                                              </div>
                                              <br/>
                                              <br/>
                                              <br/>
                                          </Col>
                                      </Row>
                                  </TabPane>
                              </TabContent>
                          </ModalBody>
                      </Modal>

                      <Modal className="removeboderB" isOpen={regimodel}  toggle={regiclose}  centered={true}  size="lg">
                          <ModalHeader  toggle={regiclose}> {''} </ModalHeader>
                          <ModalBody>

                              <div className="modal-body">
                                  <div className="container register">
                                      <div className="row">
                                          <div className="col-md-12">
                                              <div
                                                  className="tab-content mb-5 "
                                                  style={{ borderRadius: "0 0 30px 30px" }}
                                                  id="myTabContent"
                                              >
                                                  <div
                                                      className="tab-pane fade show active  form-new"
                                                      id="home"
                                                      role="tabpanel"
                                                      aria-labelledby="home-tab"
                                                  >
                                                      <form method="post" className="register" style={{marginTop: '-8px'}} onSubmit={(e)=>{Signupusers(e)}}>
                                                          <div className="row register-form-popup">
                                                              <div className="col-sm-12 p-4 mb-3 lopginmenu ">
                                                                   Register
                                                              </div>
                                                              <div className="col-ms-12">
                                                                  <ul>
                                                                  {
                                                                      Object.entries(signuperror||{}).map(function ([index, value]) {
                                                                          return <li className="text-danger text-left">{value[0]}</li>
                                                                      })
                                                                  }
                                                                  </ul>
                                                              </div>
                                                              <div className="col-md-12 mt-3">

                                                                  <div className="form-group">
                                                                      <input
                                                                          type="text"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="Name"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['name']: event.target.value })}}
                                                                          defaultValue={signup.email}
                                                                          required
                                                                      />
                                                                  </div>
                                                                  <div className="form-group">
                                                                      <input
                                                                          type="text"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="Address"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['address']: event.target.value })}}
                                                                          defaultValue={signup.address}
                                                                          required
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-sm-4">
                                                                  <div className="form-group">
                                                                      <input
                                                                          type="text"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="City"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['city']: event.target.value })}}
                                                                          defaultValue={signup.city}
                                                                          required
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-sm-4">
                                                                  <div className="form-group">
                                                                      <input
                                                                          type="text"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="State"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['state']: event.target.value })}}
                                                                          defaultValue={signup.state}
                                                                          required
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-sm-4">
                                                                  <div className="form-group">
                                                                      <input
                                                                          type="text"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="Zip code"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['zip_code']: event.target.value })}}
                                                                          defaultValue={signup.zip_code}
                                                                          required
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-sm-12">
                                                                  <div className="form-group">
                                                                      <input
                                                                          type="email"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="Email Address"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['email']: event.target.value })}}
                                                                          defaultValue={signup.email}
                                                                          required
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-sm-6">
                                                                  <div className="form-group">
                                                                      <input
                                                                          type="password"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="Create password"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['password']: event.target.value })}}
                                                                          defaultValue={signup.password}
                                                                          required
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-sm-6">
                                                                  <div className="form-group">
                                                                      <input
                                                                          type="password"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="Confirm Password"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['confirmpassword']: event.target.value })}}
                                                                          defaultValue={signup.confirmpassword}
                                                                          required
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-sm-12">
                                                                  <div className="form-group">
                                                                      <input
                                                                          type="text"
                                                                          name="LGform1_user"
                                                                          className="form-control"
                                                                          placeholder="Last 4 of SSN"
                                                                          onBlur={(event)=>{setSignup({ ...signup, ['ssn']: event.target.value })}}
                                                                          defaultValue={signup.ssn}
                                                                          required
                                                                      />
                                                                  </div>
                                                              </div>
                                                              <div className="col-sm-12">
                                                                  <div className="one1 modal-center">
                                                                      <button type="submit" href className="get-started-btn scrollto ">
                                                                          SUBMIT →
                                                                      </button>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </form>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>


                          </ModalBody>
                      </Modal>
                  </div>
              </div>


          </header>  */}

          {/* New Header start */}

          <HeaderNew homepage={false}/>

          {/* New Header end */}
           

          {/* menu bar Header */}
          {/*  <div className="fixed-top-1">
              <Mainsidebar homepage={false} expanded = {false}/>
          </div> */}


          <section id="hero" className="d-flex align-items-center justify-content-center">
          <div id="slidetextdynamic" className="home-baneer-content">
                <h1>who we are</h1>
                <p>Phantom Capital is a Private Real Estate Investment Firm</p>
                <a href="#heroen">learn more</a>
            </div>
          {is_imagesloaded == true?
            <BackgroundSlider
            images={images}
            duration={5} transition={1}/>
                    :""}
              <div className="container" data-aos="fade-up"></div>
              <a href="#heroen"><samp></samp></a>
              <a href="#heroen"><span></span></a>
          </section>



         {/*  <section id="hero1" className="space" style={{paddingTop: '50px'}}>
              <div className="container" data-aos="fade-up">
                  <div className="container" data-aos="fade-up">
                      <div className="row">
                          <div
                              className="col-lg-6 order-1 order-lg-2"
                              data-aos="fade-left"
                              data-aos-delay={100}
                          >
                             <Mapwithinfo/>
                          </div>
                          <div
                              className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content"
                              data-aos="fade-right"
                              data-aos-delay={100}
                          >
                              <h4>
                                  Phantom Partners is a nationally recognized market leader in the
                                  acquisition, development, and management of multifamily and
                                  commercial properties throughout the U.S.
                              </h4>

                              <div style={{marginLeft:'15%'}} className="order-content logindata stafflgoin  border-0 pointercss  " variant="primary" onClick={() => {setModal(true);setlogintab("2")}}>
                                  <h4 className=" ml-4 float-left text-left">INVESTOR LOGIN</h4>
                                  <p className=" ml-4 font-italic float-left">LOGIN TO YOUR PORTAL</p>
                              </div>
                              <div style={{marginLeft:'15%'}} className="order-content1  logindata border-0 pointercss  " variant="primary" onClick={() => {setModal(true);setlogintab("1")}}>
                              <h4 className=" ml-4 float-left text-left">STAFF LOGIN</h4>
                                  <p className="ml-4 font-italic float-left">LOGIN TO YOUR PORTAL</p>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
              {/* start Section */}
              {/* ======= Why Us Section ======= */}
              {/*<section id="why-us" className="why-us section-bg space">
                  <div className="container">
                      <div className="row">

                          {Object.entries(boxlist || {}).map(function ([index, value]) {
                              return <div className="col-lg-4 col-md-4 d-flex align-items-stretch space-box" key={value.image}>
                                  <div className="card">
                                      <img
                                          src={value.image}
                                          className="card-img-top"
                                      />

                                      <div className="card-body colorblack">
                                          <h5 className="card-title text-white">{value.title}</h5>
                                          <p className="card-text text-white">
                                              {value.discription}
                                          </p>
                                      </div>
                                  </div>
                              </div>
                          })}
                      </div>
                  </div>
              </section>
              {/* End Why Us Section */}
              {/*<div className="space" />
          </section> */}
           
           <section id="heroen" className="space" style={{paddingTop: '50px'}}>
                  
                    <div className="col-lg-12">
                    <div className="maindiv">
                    <div className="flex-container">
                    <div className="flex-container-div-1">
                    <h3 className="dark-blue">3 YRS</h3>
                    <h5 className="dark-blue">IN BUSINESS</h5>
                    </div>
                    <div className="flex-container-div-1">
                    <h3 className="blue"><span>80</span></h3>
                    <h6 className="blue">MULTIFAMILY UNITES</h6>
                    </div></div>
                    <div className="flex-container">
                    <div className="flex-container-div-2">
                    <h3 className="blue spaces-h3 h3-text">20 Million </h3>
                    <h6 className="blue h5-text-light">ASSETS UNDER MANAGEMENT</h6>
                    </div></div>
                    <div className="flex-container">
                    <div className="flex-container-div-3 float-right">
                    <h3 className="blue "><span>21</span></h3>
                    <h5 className="blue ">INVESTORS</h5>
                    <h5 className="blue ">WORLDWIDE</h5>
                    </div>
                    <div className="flex-container-div-3 float-left">
                    <h6 className="dark-blue ">EXCEEDING</h6>
                    <h3 className="dark-blue " >$50 Million </h3>
                    <h6 className="dark-blue ">IN ACQUISITIONS</h6>
                    </div></div></div></div>
                    </section> 

          <Footer/> 


         


      </>
    )
};



export default Home;
