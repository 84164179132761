import { Link, useHistory } from "react-router-dom";
import React, {useEffect, useState,Component } from 'react';
import ScrollToTop from "react-scroll-to-top";

const Footer = () => {
    return (
        <>
           <div id="footer_bg" className="footer_4_col footer_type_2">
                <div className="container">
                <div id="footerr">
                <div className="widget_wrap">
                <div className="widget widget_text"> <div className="textwidget"><p><img className="white_logo desktop_logo" src="/WEB-logo-footer4.png" alt=""/></p>
                </div>
                </div> </div>
                <div className="widget_wrap">

				
				<div className="widget widget_text contact-footer">			<div className="textwidget"><p className="heading-cont"><strong>Phantom CAPITAL</strong></p>
                    <p>124-15 Rockaway<br/>
                    Blvd Ozone Park NY 11420<br/>
                    </p>
                    <p><i className="fa fa-phone"></i> 718-215-1000</p>
                    <p><i className="fa fa-fax"></i> 718-228-5283</p>
                    <p><Link to="mailto:contact@phantompartner.com"><i className="fa fa-envelope "></i> contact@phantompartner.com</Link></p>
                    </div>
                            </div>
                                    
				
				</div>
			<div className="widget_wrap">
			<div className="widget widget_text"> <div className="textwidget">
				
				<ul>
					<li><Link to="/">Home</Link></li>
				<li><Link to="/about">About Us</Link></li>
				<li><Link to="/lending">Lending</Link></li>
				<li><Link to="/invest-with-us">Invest With Us</Link></li>
				<li><Link to="/portfolio">Portfolio</Link></li>
				<li><Link to="/contact">Contact</Link></li>
				</ul>
				</div>
				</div> </div>
                <div className="widget_wrap">
                <div className="widget rd_social_widget"><h2>CONNECT WITH US</h2><div className="sc_small_line widget_line"><span className="small_l_left"></span></div><div className="thefox_social_widget"><div className="thefox_social_widget_icons clearfix"> <div id="facebook"> <Link to="#" target="_blank"><i className="fa fa-facebook"></i></Link></div>
                <div id="twitter"> <Link to="#" target="_blank"><i className="fa fa-twitter"></i></Link></div>
                <div id="yt"> <Link to="#" target="_blank"><i className="fa fa-youtube"></i></Link></div>
                <div id="lin"> <Link to="#" target="_blank"><i className="fa fa-linkedin"></i></Link></div>
                <div id="instagram"> <Link to="#" target="_blank"><i className="fa fa-instagram"></i></Link></div>
                </div></div></div> </div>
                </div>
                </div>

                <div id="footer_coms">
                <div className="container">

                <div className="f_menu_left m_normal">
                </div>
                <div className="footer_message f_message_left">

                © 2021 PHANTOM CAPITAL &nbsp;&nbsp;|&nbsp;&nbsp; ALL RIGHTS RESERVED</div>


                </div>
                </div>
                </div>

        </>
    )
};

export default Footer;
