import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle,FormGroup,Label,Input, CardSubtitle, UncontrolledTooltip, Modal} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import "../../assets/scss/datatables.scss";
import {confirmAlert} from "react-confirm-alert";
import Select from "react-select";
const axios = require('axios');


const CurrentProperties = () => {
    const [list, setlist] = useState([]);
    const [count, setCount] = useState(0);
    const [model, setModel] = useState(false);
    const [pdf, Setpdf] = useState({});
    const [boxerror, setboxerror] = useState({});
    const [Employeelist, setEmployeelist] = useState({});
    const usersession = sessionStorage.getItem('authUser');
    const user = JSON.parse(usersession);

    useEffect(() => {
        Pages();
    },[]);

    const Pages = () => {
        axios.get(process.env.REACT_APP_BASE_URL + "auth/emp-current-propertieslist",{headers: {'Authorization': `${user.access_token}`}})

            .then(function (response) {
                if(response.data.error == 0)
                    setlist(response.data)
            })
            .catch(function (error,response) {
                console.log("error",error)
            });

    };




    const optionsvalues =  [
        {value:1, label:"Submitted"},
        {value:2, label:"In process"},
        {value:3, label:"Missing Documents"},
        {value:4, label:"Pending Appraisal"},
        {value:5, label:"Offer Negotiation"},
        {value:6, label:"Short Sell Approval"},
        {value:7, label:"Title Clearance/Schedule a Closing"},
        {value:8, label:"Completed"}
    ];

    const columns = [
        {
            'text': "Address",
            'dataField': "address",
            headerAlign: 'center','align': 'center',
        },
        {
            'text': "Servicing Bank",
            'dataField': "servicing_bank",
            headerAlign: 'center','align': 'center',
        },
        {
            'text': "Assign to",
            'dataField': "assign",
            headerAlign: 'center','align': 'center',
             formatter:  (cell) => cell.label,
        },

        {
            'text': "Type of Property",
            'dataField': "type_of_property",
            headerAlign: 'center','align': 'center',
        },
        {
            'text': "",
            'dataField': "url",
            formatter: Imageset,
            headerAlign: 'center','align': 'center',
        },

        {
            'text': "Status Details",
            'dataField': "status",
            formatter: ActionStatus,
            headerAlign: 'center','align': 'center',
        },


    ];




    function ActionStatus(cell, row, rowIndex, formatExtraData) {
        return (
            <Link    to={'current-properties/'+row.id}  className="btn btn-rounded btn-dark btn-sm font-size-12 px-3">View Status Details</Link>
        ); }

    function Delete(id){
        confirmAlert({
            title: 'Confirm to Delete',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.get(process.env.REACT_APP_BASE_URL+"auth/current-propertiesDelete/"+id)
                            .then(function (response) {
                                Pages()
                            })
                            .catch(function (error,response) {
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () =>{}
                }
            ]
        });
    }

    function statuschange(id,status){
        console.log("status",status);
        confirmAlert({
            title: 'Are you sure to do this.',
            message: 'Confirm to Change Status',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        axios.get(process.env.REACT_APP_BASE_URL+"auth/current-propertiesStatus/"+id+'/'+status.value)
                            .then(function (response) {
                                Pages()
                            })
                            .catch(function (error,response) {
                            });
                    }
                },
                {
                    label: 'No',
                    onClick: () =>{}
                }
            ]
        });
    }

    function Imageset(cell, row, rowIndex, formatExtraData) {
        return (
            <a href={cell} target="_blank" className="btn btn-rounded btn-sm btn-dark font-size-12 px-3">View File</a>
        ); }

    function Dropdownset(cell, row, rowIndex, formatExtraData) {
        return (
            <div className="form-group">
                <FormGroup>
                    <Select
                        maxMenuHeight={200}
                        value={{value:row.status, label:cell}}
                        onChange={(value)=>{statuschange(row.id,value)}}
                        options={optionsvalues}
                    />
                </FormGroup>
            </div>
        ); }





    const onFileChange = event => {
        Setpdf({...pdf,'file':event.target.files[0]})
    };

    const savepost=()=>{

        const formData = new FormData();

        Object.entries(pdf).forEach(([v, k]) => {
            formData.append(v, k);
        });

        if(pdf.file)
            formData.append("file",pdf.file,pdf.file.name);
        if(pdf.id)
        formData.append("id",pdf.id);

        axios.post(process.env.REACT_APP_BASE_URL+"auth/current-Properties", formData)
            .then(function (response) {
                Pages();
                setModel(false);
                setboxerror({});
                Setpdf({});
            })
            .catch(function (error,response) {
                setboxerror(error.response.data.errors)
            });
    }
    const sizePerPageRenderer = ({
                                     options,
                                     currSizePerPage,
                                     onSizePerPageChange
                                 }) => (
        <div className="btn-group border" role="group">
            {
                options.map(option => (
                    <button
                        key={ option.text }
                        type="button"
                        onClick={ () => onSizePerPageChange(option.page) }
                        className={ `btn ${currSizePerPage === `${option.page}` ? 'btn-secondary' : 'btn-warning'}` }
                    >
                        { option.text }
                    </button>
                ))
            }
        </div>
    );

    const options = {
        sizePerPageRenderer
    };

    return (
        <React.Fragment>
            <div className="page-content">

                <div className="container-fluid">
                    <Breadcrumbs title="Tables" breadcrumbItem="Current Properties"/>
                    <Row>
                        <Col className="col-12">
                            <Card>
                                <CardBody>
                                    <div className="text-center">

                                    </div>
                                    <BootstrapTable
                                        striped
                                        hover
                                        keyField="dn"
                                        data={list.list||[]}
                                        columns={columns}
                                        pagination={ paginationFactory(options)}
                                        classes="custom-table-my"
                                    />
                                </CardBody>
                            </Card>
                        </Col>

                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
}

export default CurrentProperties;

