import React, {useState, useEffect} from "react";
import { Container, Row, Col, Button, Card, CardBody, CardTitle, Modal, ModalHeader, ModalBody, ModalFooter, Media, Table } from "reactstrap";
import { Link } from "react-router-dom";


//import Charts
import StackedColumnChart from "./StackedColumnChart";

import modalimage1 from "../../assets/images/product/img-7.png";
import modalimage2 from "../../assets/images/product/img-4.png";

// Pages Components
import WelcomeComp from "./WelcomeComp";
import MonthlyEarning from "./MonthlyEarning";
import SocialSource from "./SocialSource";
import ActivityComp from "./ActivityComp";
import TopCities from "./TopCities";
import LatestTranaction from "./LatestTranaction";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//i18n
import { withNamespaces } from 'react-i18next';
const axios = require('axios');
const Dashboard = (props) => {

    const [modal, setmodal] = useState(false);
    const [count, setcount] = useState(false);
    const [report, setreports] = useState({});
    const usersession = sessionStorage.getItem('authUser');
    const user = JSON.parse(usersession);

    useEffect((props) => {
        setmodal(false);
        pages()
    },[]);


    const pages = () => {

        axios.get(process.env.REACT_APP_BASE_URL + "auth/userdashboard", {headers: {'Authorization': `${user.access_token}`}})
            .then(function (response) {
                setcount(response.data.count);
            })
            .catch(function (error, response) {

            });
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumb */}
                    <Breadcrumbs title={props.t('Dashboard')} breadcrumbItem={props.t('Dashboard')} />

                    <Row>
                        <Col xl="4">
                            <WelcomeComp />
                        </Col>
                        <Col xl="8">


                            <Row>
                                <Col md="6" className="afterprofiletext2" >
                                    <Link to="current-properties">
                                        <Card className="mini-stats-wid"  >
                                            <CardBody>
                                                <Media>

                                                    <Media body>
                                                        <p  className="text-dark font-weight-medium text-center mt-3 font-size-15 font-weight-bold">Current Properties</p>
                                                        <h4 className="text-dark font-weight-medium text-center mt-3 font-size-18 font-weight-bold  ">{count}</h4>
                                                    </Media>
                                                    <div
                                                        className="mini-stat-icon avatar-sm rounded-circle bg-warning align-self-center">
                                                                <span className="avatar-title  " style={{'backgroundColor': 'rgb(255, 191, 0)'}}>
                                                                    <i className="bx bx-check-circle font-size-24"></i>
                                                                </span>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col md="6" className="afterprofiletext2" >
                                    <Link to="employment-contract">
                                        <Card className="mini-stats-wid"  >
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <p  className="text-dark font-weight-medium text-center mt-3 font-size-15 font-weight-bold">Employment Contract</p>
                                                        <h4 className="text-dark font-weight-medium text-center mt-3 font-size-18 font-weight-bold  " style={{'opacity':0}}>{count}</h4>
                                                    </Media>
                                                    <div
                                                        className="mini-stat-icon avatar-sm rounded-circle bg-warning align-self-center">
                                                                <span className="avatar-title  " style={{'backgroundColor': 'rgb(255, 191, 0)'}}>
                                                                    <i className="bx bx-check-circle font-size-24"></i>
                                                                </span>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col md="6" className="afterprofiletext2">
                                    <Link to="employment-handbook">
                                        <Card className="mini-stats-wid"  >
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <p  className="text-dark font-weight-medium text-center mt-3 font-size-15 font-weight-bold">Employee Handbook</p>
                                                        <h4 className="text-dark font-weight-medium text-center mt-3 font-size-18 font-weight-bold " style={{'opacity':0}}>{count}</h4>

                                                    </Media>
                                                    <div
                                                        className="mini-stat-icon avatar-sm rounded-circle bg-warning align-self-center">
                                                                <span className="avatar-title  " style={{'backgroundColor': 'rgb(255, 191, 0)'}}>
                                                                    <i className="bx bx-check-circle font-size-24"></i>
                                                                </span>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Link>
                                </Col>
                                <Col md="6" className="afterprofiletext2">
                                    <Link to="training">
                                        <Card className="mini-stats-wid"  >
                                            <CardBody>
                                                <Media>
                                                    <Media body>
                                                        <p  className="text-dark font-weight-medium text-center mt-3 font-size-15 font-weight-bold">Training</p>
                                                        <h4 className="text-dark font-weight-medium text-center mt-3 font-size-18 font-weight-bold  " style={{'opacity':0}}>{count}</h4>

                                                    </Media>
                                                    <div
                                                        className="mini-stat-icon avatar-sm rounded-circle bg-warning align-self-center">
                                                                <span className="avatar-title  " style={{'backgroundColor': 'rgb(255, 191, 0)'}}>
                                                                    <i className="bx bx-check-circle font-size-24"></i>
                                                                </span>
                                                    </div>
                                                </Media>
                                            </CardBody>
                                        </Card>
                                    </Link>
                                </Col>



                            </Row>


                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(Dashboard);
