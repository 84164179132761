import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
const Sidebarmain = (props) => {

    const [sidebarmodels, setsidebarmodels] = useState(false);


    useEffect(() => {
        console.log('props',props)
        setsidebarmodels(props.expanded)
    }, [props]);


    return (
        <>
            {sidebarmodels === false ?
                <button type="button" className="nav-toggle hideandshowlogo " onClick={() => {
                    setsidebarmodels(true)
                }}>
                    <img src="https://i.postimg.cc/MK9pZYqF/WEB-logo-icon.png" width="40px;"/>
                </button> : ''}


            {sidebarmodels === true ?
                <div className={sidebarmodels === true ? '' : ''}>
                    <div className={sidebarmodels === true ? 'sidebarmodel' : ''}>
                        <div className="modal-center">
                            {props.homepage == false ?
                                <div className="modal-header" onClick={() => {
                                    setsidebarmodels(false)
                                }}>
                                    <button
                                        type="button"
                                        className="close1"
                                        data-dismiss="modal"
                                        aria-label="Close"

                                    >
            <span aria-hidden="true">
              <a>
                <img
                    src="https://i.postimg.cc/q7zSRV8f/close1.png"
                    width="20px;"
                />
              </a>
            </span>
                                    </button>
                                </div>
                                :
                                <div className="modal-header">
                                    <button
                                        type="button"
                                        className="close1"
                                        data-dismiss="modal"
                                        aria-label="Close"

                                    >
            <span aria-hidden="true">

            </span>
                                    </button>
                                </div>}

                            <div className="modal-body">
                                <Link to="/">
                                    <img
                                        src="https://i.postimg.cc/MK9pZYqF/WEB-logo-icon.png"

                                        width="70px;"
                                    />
                                    </Link>
                            </div>
                            <div className="modal-menu">
                                <nav className="nav-side-menu d-lg-block">
                                    <ul>
                                        <li>
                                            <Link to="/about">About Us</Link>
                                        </li>
                                        <li>
                                            <a href="http://www.phantomrealtynyc.com">Listings</a>
                                        </li>
                                        <li>
                                            <Link to="/lending">Lending</Link>
                                        </li>
                                        <li>
                                            <Link to="/invest-with-us">Invest With Us</Link>
                                        </li>
                                        <li>
                                            <Link to="/portfolio">Portfolio</Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">Contact</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                : ''}

        </>
    );
};


export default Sidebarmain;
