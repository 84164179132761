import React, {useEffect, useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import {Row, Col, Card, CardBody, CardTitle,FormGroup,Label,Input, CardSubtitle, UncontrolledTooltip, Modal} from "reactstrap";
import Footer from "../../layout/footer";
import Mainsidebar from "./mainsidebar";
import 'react-fancybox/lib/fancybox.css'
import '../../assets/css/home.css'
import Iframe from 'react-iframe'
import {Animated} from "react-animated-css";
const axios = require('axios');



const About = (props) => {

	const [Contact, setContact] = useState(false);
	const [tab, settab] = useState('SALES');
	const [model, setmodel] = useState(false);
	const [loader, Setloader] = useState(true);


	useEffect(() => {
		getdatatoshow()
	}, []);

	const getdatatoshow = () => {
		axios.get(process.env.REACT_APP_BASE_URL+"auth/portfoliodetails")
			.then(function (response) {
				if(response.data.error == 0)
					setContact(response.data.list)
			})
			.catch(function (error,response) {
				console.log("error",error)
			}) .then(function () {
			Setloader(false)
		});

	};
	var sectionStyle = {
		backgroundImage: `url(${Contact.image})`
	};
	const handleClose=()=>{
		return  true;
	}



	return (
		<>

			<div className="fixed-top-1">
				<Mainsidebar homepage={false} expanded = {false}/>
			</div>
			{loader === true?<div id="loaderdiv"><div id="loader"></div></div>:''}
			<section id="hero1">
				{/* top item start */}
				<div className="container space-otherpage-top">
					<div className="lending-header">
						<h1 className="lending-header-h1">LISTINGS</h1>
					</div>
					<div className="row pt-5">
						<div className="col-lg-12">
							<div className="container">
								<ul
									className="nav nav-tabs portfolio"
									style={{ paddingLeft: 0, paddingRight: 0 }}
								>
									<li className="portfolio-title">
										<a className={tab =="SALES"?'active':''} data-toggle="tab" onClick={()=>{settab('SALES')}}>
											SALES
										</a>
									</li>

									<li className="portfolio-title">
										<a className={tab =="RENTALS"?'active':''} data-toggle="tab" onClick={()=>{settab('RENTALS')}}>
											RENTALS
										</a>
									</li>
									<li className="portfolio-title">
										<a className={tab =="OPEN HOUSES"?'active':''} data-toggle="tab" onClick={()=>{settab('OPEN HOUSES')}}>
											OPEN HOUSES
										</a>
									</li>


								</ul>
								<div className="tab-content">



									{tab =="SALES"?
										<Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay={500}  isVisible={tab =="SALES"?true:false}>
									<div id="home" className="tab-pane fade in active show">
										<div className="row">
											<div className="col-sm-12 col-md-6">
												<div className="">
													<div className="p-2 float-right">
														<Link to={'listings/'+tab+'/queens'} className="fancybox fancybox.iframe listing-image-right">
															<img
																className="img rounded mx-auto d-block listing-image "
																src={"https://i.postimg.cc/3RQW0vqs/Queens.jpg"}
															/>
															<div className="listing-overlay float-right">
																<p className="listing-overlay-title align-middle">
																	{"Queens"}
																</p>
															</div>
														</Link>
													</div>
												</div>
											</div>
											<div className="col-sm-12 col-md-6">
												<div className="">
													<div className="p-2 flex-fill bd-highlight">
														<Link to={'listings/'+tab+'/manhattan'}
															className="fancybox fancybox.iframe listing-image"
														>
															<img
																className="img rounded mx-auto d-block listing-image "
																src={"https://i.postimg.cc/HWK4ZSfH/Manhattan-1.jpg"}
															/>
															<div className="listing-overlay">
																<p className="listing-overlay-title">
																	{"Manhattan"}
																</p>
															</div>
														</Link>
													</div>
												</div>
											</div>
											<div className="col-sm-12 col-md-6">
												<div className="">
													<div className="p-2 float-right">
														<Link to={'listings/'+tab+'/brooklyn'}
															className="fancybox fancybox.iframe listing-image-right"
														>
															<img
																className="img rounded mx-auto d-block listing-image "
																src={"https://i.postimg.cc/tgXgNjkT/brooklyn.jpg"}
															/>
															<div className="listing-overlay float-right">
																<p className="listing-overlay-title">
																	{"Brooklyn"}
																</p>
															</div>
														</Link>
													</div>
												</div>
											</div>
											<div className="col-sm-12 col-md-6">
												<div className="">
													<div className="p-2 flex-fill bd-highlight">
														<Link to={'listings/'+tab+'/bronx'}
															className="fancybox fancybox.iframe listing-image"
														>
															<img
																className="img rounded mx-auto d-block listing-image "
																src={"https://i.postimg.cc/br2YwNy6/bronx.jpg"}
															/>
															<div className="listing-overlay">
																<p className="listing-overlay-title">
																	{"Bronx"}
																</p>
															</div>
														</Link>
													</div>
												</div>
											</div>


										</div>
									</div>
										</Animated>
									:""}

									{tab =="RENTALS"?
										<Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay={500} isVisible={tab =="RENTALS"?true:false}>
										<div id="home" className="tab-pane fade in active show">
											<div className="row">
												<div className="col-sm-12 col-md-6">
													<div className="">
														<div className="p-2 float-right">
															<Link to={'listings/'+tab+'/queens'} className="fancybox fancybox.iframe listing-image-right">
																<img
																	className="img rounded mx-auto d-block listing-image "
																	src={"https://i.postimg.cc/3RQW0vqs/Queens.jpg"}
																/>
																<div className="listing-overlay float-right">
																	<p className="listing-overlay-title align-middle">
																		{"Queens"}
																	</p>
																</div>
															</Link>
														</div>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div className="">
														<div className="p-2 flex-fill bd-highlight">
															<Link to={'listings/'+tab+'/manhattan'}
																  className="fancybox fancybox.iframe listing-image"
															>
																<img
																	className="img rounded mx-auto d-block listing-image "
																	src={"https://i.postimg.cc/HWK4ZSfH/Manhattan-1.jpg"}
																/>
																<div className="listing-overlay">
																	<p className="listing-overlay-title">
																		{"Manhattan"}
																	</p>
																</div>
															</Link>
														</div>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div className="">
														<div className="p-2 float-right">
															<Link to={'listings/'+tab+'/brooklyn'}
																  className="fancybox fancybox.iframe listing-image-right"
															>
																<img
																	className="img rounded mx-auto d-block listing-image "
																	src={"https://i.postimg.cc/tgXgNjkT/brooklyn.jpg"}
																/>
																<div className="listing-overlay float-right">
																	<p className="listing-overlay-title">
																		{"Brooklyn"}
																	</p>
																</div>
															</Link>
														</div>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div className="">
														<div className="p-2 flex-fill bd-highlight">
															<Link to={'listings/'+tab+'/bronx'}
																  className="fancybox fancybox.iframe listing-image"
															>
																<img
																	className="img rounded mx-auto d-block listing-image "
																	src={"https://i.postimg.cc/br2YwNy6/bronx.jpg"}
																/>
																<div className="listing-overlay">
																	<p className="listing-overlay-title">
																		{"Bronx"}
																	</p>
																</div>
															</Link>
														</div>
													</div>
												</div>


											</div>
										</div>
										</Animated>
										:""}

									{tab =="OPEN HOUSES"?
										<Animated animationIn="fadeIn" animationOut="fadeOut" animationInDelay={500} isVisible={tab =="OPEN HOUSES"?true:false}>
										<div id="home" className="tab-pane fade in active show">
											<div className="row">
												<div className="col-sm-12 col-md-6">
													<div className="">
														<div className="p-2 float-right">
															<Link to={'listings/'+tab+'/queens'} className="fancybox fancybox.iframe listing-image-right">
																<img
																	className="img rounded mx-auto d-block listing-image "
																	src={"https://i.postimg.cc/3RQW0vqs/Queens.jpg"}
																/>
																<div className="listing-overlay float-right">
																	<p className="listing-overlay-title align-middle">
																		{"Queens"}
																	</p>
																</div>
															</Link>
														</div>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div className="">
														<div className="p-2 flex-fill bd-highlight">
															<Link to={'listings/'+tab+'/manhattan'}
																  className="fancybox fancybox.iframe listing-image"
															>
																<img
																	className="img rounded mx-auto d-block listing-image "
																	src={"https://i.postimg.cc/HWK4ZSfH/Manhattan-1.jpg"}
																/>
																<div className="listing-overlay">
																	<p className="listing-overlay-title">
																		{"Manhattan"}
																	</p>
																</div>
															</Link>
														</div>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div className="">
														<div className="p-2 float-right">
															<Link to={'listings/'+tab+'/brooklyn'}
																  className="fancybox fancybox.iframe listing-image-right"
															>
																<img
																	className="img rounded mx-auto d-block listing-image "
																	src={"https://i.postimg.cc/tgXgNjkT/brooklyn.jpg"}
																/>
																<div className="listing-overlay float-right">
																	<p className="listing-overlay-title">
																		{"Brooklyn"}
																	</p>
																</div>
															</Link>
														</div>
													</div>
												</div>
												<div className="col-sm-12 col-md-6">
													<div className="">
														<div className="p-2 flex-fill bd-highlight">
															<Link to={'listings/'+tab+'/bronx'}
																  className="fancybox fancybox.iframe listing-image"
															>
																<img
																	className="img rounded mx-auto d-block listing-image "
																	src={"https://i.postimg.cc/br2YwNy6/bronx.jpg"}
																/>
																<div className="listing-overlay">
																	<p className="listing-overlay-title">
																		{"Bronx"}
																	</p>
																</div>
															</Link>
														</div>
													</div>
												</div>


											</div>
										</div>
										</Animated>
										:""}
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<Footer/>

		</>
	)
};


export default About ;
